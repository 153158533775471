.container {
  text-align: center;
  width: 100vw;
  margin: 0 auto;
  margin-top: 50px;
  font-family: 'Open Sans', sans-serif;
}

.header {
  font-size: 20px;
  font-weight: bold;
}
